<template>
  <div>
    <v-row justify="end" class="mb-4">
      <v-col
        ><v-row>
          <v-col sm="12" md="2">
            <BaseDatePickerWithText
              label="Current Status Begin"
              clearable
              v-model="searchStartDate"
            >
            </BaseDatePickerWithText>
          </v-col>
          <v-col sm="12" md="2">
            <BaseDatePickerWithText
              label="Current Status End"
              clearable
              v-model="searchEndDate"
            >
            </BaseDatePickerWithText>
          </v-col>
          <v-col sm="12" md="4"
            ><LuCounty
              class="pt-1"
              clearable
              :items="filteredCounties"
              v-model="searchCountyId"
            ></LuCounty
          ></v-col>
          <v-col sm="12" md="4"
            ><LuCity
              class="pt-1"
              clearable
              :items="filteredCities"
              v-model="searchCityId"
            ></LuCity
          ></v-col>
          <v-col sm="12" md="4"
            ><LuApplicationStatus
              class="pt-1"
              v-model="searchStatusId"
              :showSelectAll="true"
            ></LuApplicationStatus
          ></v-col>
          <v-col sm="12" md="4"
            ><LuReason
              class="pt-1"
              v-model="searchReasonId"
              sortProperty="ReasonNumber"
            ></LuReason
          ></v-col>
          <v-col sm="12" md="4"
            ><LuPeriod
              class="pt-1"
              v-model="searchApplicationPeriodId"
            ></LuPeriod
          ></v-col> </v-row
      ></v-col>
      <v-col class="ml-auto" sm="12" md="2">
        <v-text-field
          v-model="searchText"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          hide-details="auto"
          single-line
          hint="FirstName, LastName, SSN, Phone, Parcel, StreetAddress1, StreetAddress2, Zip, Application Number, Status, Reason, Refund Amount"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- //templateTodo: if you use a custom search dto uncomment this to allow showing of filter params-->
    <!-- <v-row justify="center">
          <v-col sm="12" md="2">
              <h4 @click="toggleFilters" class="pointer primary--text">{{filterText}}
              </h4>
          </v-col>
      </v-row> -->
    <v-row v-if="filters">
      <!-- The Description and ID (item-text and item-value) 
          will need to be adjusted based on the properties in the array/lookup table
          these availableLists should be pulled from lookup tables and potentially
          filtered with a computed as desired (for IsActive status etc)
          These can also be switched to v-autocompletes if desired-->

      <!-- <v-col cols="12" sm="auto">
        <v-switch v-model="searchShowInactive" label="Show Inactive"></v-switch>
        </v-col> -->
      <!-- <v-col cols="12" sm="auto">
              <v-select 
                  v-model="searchPropertyIsActive" 
                  :items="['true', 'false']"
                  label="Property Is Active"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
    </v-row>
  </div>
</template>

<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuCounty from '@components/select/LuCounty/LuCounty-a-multi-id'
import LuCity from '@components/select/LuCity/LuCity-a-multi-id'
import LuPeriod from '@components/select/LuPeriod/LuPeriod-a-multi-id'
import LuApplicationStatus from '@components/select/LuApplicationStatus/LuApplicationStatus-a-multi-id'
import LuReason from '@components/select/LuReason/LuReason-a-multi-id'
export default {
  name: 'VwSummarySearchSearchParams',
  data: () => ({
    filters: false,
    filterText: '-Hide Filters-',
  }),
  components: {
    LuCounty,
    LuCity,
    LuPeriod,
    LuApplicationStatus,
    LuReason,
  },
  created() {
    this.loadCurrentLuPeriod()
    this.loadLuCounties()
    this.loadLuCities()
    this.loadLuPeriods()
    this.loadLuApplicationStatuses()
    this.loadLuReasons()
  },
  mounted() {
    let lastStatus = localStorage.getItem('inboxStatus')
    if (lastStatus != null && this.searchStatusId.length == 0) {
      try {
        let status = JSON.parse(lastStatus)
        // console.devlog(status)
        this.searchStatusId = status
      } catch {}
    }
  },
  watch: {
    currentLuPeriod(nval, oval) {
      if (nval?.Id > 0 && this.searchApplicationPeriodId.length == 0) {
        this.searchApplicationPeriodId = [nval.Id]
      }
    },
    searchStatusId(nval, oval) {
      localStorage.setItem('inboxStatus', JSON.stringify(nval))
    },
  },
  computed: {
    ...get('luPeriod', ['currentLuPeriod']),
    ...sync('vwSummarySearch', [
      'searchText',
      'searchCountyId',
      'searchCityId',
      'searchApplicationPeriodId',
      'searchStatusId',
      'searchReasonId',
      //templateTODO: uncomment as used in VwSummarySearchSearchParams
      'searchStartDate',
      'searchEndDate',
      // 'searchShowInactive',
      // 'searchPropertyIsActive',
    ]),
    ...get('luCounty', ['luCounties']),
    ...get('luCity', ['luCities']),
    ...get('luPeriod', ['luPeriods']),
    ...get('luApplicationStatus', ['luApplicationStatuses']),
    ...get('luReason', ['luReason']),
    //templateTODO: custom available lookup computed properties should go here
    filteredCounties() {
      if (
        this.searchCityId.length > 0 &&
        this.luCities &&
        this.luCities.length > 0
      ) {
        var filteredCounties = []
        this.luCities.forEach((x) => {
          if (this.searchCityId.includes(x.Id)) {
            x.Counties.forEach((y) => {
              if (!filteredCounties.find((i) => i.Id == y.Id)) {
                filteredCounties.push(y)
              }
            })
          }
        })
        return filteredCounties
      } else {
        return null
      }
    },
    filteredCities() {
      if (
        this.searchCountyId.length > 0 &&
        this.luCounties &&
        this.luCounties.length > 0
      ) {
        var filteredCities = []
        this.luCounties.forEach((x) => {
          if (this.searchCountyId.includes(x.Id)) {
            x.Cities.forEach((y) => {
              if (!filteredCities.find((i) => i.Id == y.Id)) {
                filteredCities.push(y)
              }
            })
          }
        })
        return filteredCities
      } else {
        return null
      }
    },
    //templateTODO: custom available lookup computed properties should go here
  },
  methods: {
    ...call('luPeriod', ['loadCurrentLuPeriod']),
    loadLuCounties: call('luCounty/loadLuCounties'),
    loadLuCities: call('luCity/loadLuCities'),
    loadLuPeriods: call('luPeriod/loadLuPeriods'),
    loadLuApplicationStatuses: call(
      'luApplicationStatus/loadLuApplicationStatuses'
    ),
    loadLuReasons: call('luReason/loadLuReasons'),
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
  },
}
</script>

<style lang="scss"></style>
