<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h4 primary--text">Application Inbox</h1>
        </v-col>
        <v-col cols="12">
          <VWSummarySearch></VWSummarySearch>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import VWSummarySearch from '@components/search/VwSummarySearch'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: { VWSummarySearch },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
